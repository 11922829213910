<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage gate pass</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Gate pass
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('gate-passes', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addGatePass">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add gate pass
                                    </v-btn>&nbsp;&nbsp;

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field class="form-control" v-model="search.info"
                                        label="Name, email, or CRN" outlined clearable
                                        v-on:keyup.enter="getGatePasses()" dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            label="Date of issue"
                                            outlined
                                            readonly 
                                            v-on:keyup.enter="getGatePasses"
                                            v-bind="attrs"
                                            v-model="search.date_time"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu = false"
                                        v-model="search.date_time"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="getGatePasses()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Student</th>
                                        <th class="px-3 wrap-column">Date of issue</th>
                                        <th class="px-3 wrap-column">Program</th>
                                        <th class="px-3 wrap-column">Reason</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="gatePasses.length > 0" v-for="(item, index) of gatePasses" :key="item.id">
                                        <td class="px-3 wrap-column">
                                            <strong> 
                                                <a href="#" @click="gotoUserProfile(item.user_id)" class="mr-2"> {{ item.user_crn
                                                }}</a>
                                            </strong>
                                            <div>
                                                <a href="#" @click="gotoUserProfile(item.user_id)"> {{ item.user_full_name ? item.user_full_name : 'NA' }} </a>
                                            </div>
                                            <small>
                                                {{ item.user_email }}
                                            </small>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div class="wrap-column">
                                                {{ item.date_of_issue ? item.date_of_issue : 'NA' }}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div>
                                                {{ item.program_title ? item.program_title : 'NA'}}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div v-if="item.reason" v-html="item.reason"></div>
                                        </td>
                                        <td class="px-3 pt-3 pb-3 wrap-column">
                                            <span 
                                                class="badge text-lg "
                                                v-bind:class="{ 'badge-success': item.is_used, 'badge-danger': !item.is_used }">
                                                {{ item.is_used ? 'Used' : 'Not used' }}
                                            </span>

                                            <div class="mt-3">
                                                <span   
                                                    v-if="item.approval_status == 'pending'"
                                                    class="badge badge-warning text-lg text-capitalize"
                                                >
                                                    {{ item.approval_status }}
                                                </span>

                                                <span 
                                                    v-if="item.approval_status == 'approved'"
                                                    class="badge text-lg text-capitalize badge-success"
                                                >
                                                    {{ item.approval_status }}
                                                </span>

                                                <span 
                                                    v-if="item.approval_status == 'rejected'"
                                                    class="badge text-lg badge-danger text-capitalize"
                                                >
                                                    {{ item.approval_status }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('gate-passes', 'edit') && item.is_approved" >
                                                        <a href="#" class="navi-link" @click="changeIsUsedStatus(item.id)">
                                                            <span class="navi-icon">
                                                                <i v-if="!item.is_used" class="fa fa-check"></i>
                                                                <i v-if="item.is_used" class="fa fa-ban"></i>
                                                            </span>
                                                            <span class="navi-text" v-if="item.is_used"> Mark as unused </span>
                                                            <span class="navi-text" v-if="!item.is_used"> Mark as used </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" 
                                                        v-if="checkIsAccessible('gate-passes', 'edit') && (item.approval_status == 'pending' 
                                                                                                        || item.approval_status == 'rejected') 
                                                                                                        && !item.is_used" >
                                                        <a href="#" class="navi-link" @click="approve(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-check"></i>
                                                            </span>
                                                            <span class="navi-text"> Approve </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" 
                                                        v-if="checkIsAccessible('gate-passes', 'edit') 
                                                                                && (item.approval_status=='pending' 
                                                                                || item.approval_status=='approved') 
                                                                                && !item.is_used" >
                                                        <a href="#" class="navi-link" @click="disapprove(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-ban"></i>
                                                            </span>
                                                            <span class="navi-text"> Reject </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('gate-passes', 'generate-pdf')" >
                                                        <a href="#" class="navi-link" @click="generatePdf(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="ml-1 fa fa-file-pdf"></i>
                                                            </span>
                                                            <span class="navi-text"> Generate PDF </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('gate-passes', 'edit')" >
                                                        <a href="#" class="navi-link" @click="editGatePass(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('gate-passes', 'delete')" >
                                                        <a href="#" class="navi-link" @click="deleteGatePass(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="gatePasses.length == 0">
                                        <td class="text-center  px-3" colspan="7">Data not available</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="gatePasses.length > 0" 
                                @input="getGatePasses" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update ref="gate-pass" @refresh="getGatePasses"></create-and-update>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import GatePassService from "@/core/services/gate-pass/GatePassService";
import CreateAndUpdate from "./CreateAndUpdate"

const gatePass = new GatePassService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                info: '',
                date_time: '',
            },
            loading: false,
            gatePasses: [],
            page: null,
            perPage: null,
            total: null,
            menu: false,
        }
    },
    methods: {
        getGatePasses() {
            this.loading = true;
            gatePass
                .paginate(this.search, this.page)
                .then(response => {
                    this.gatePasses = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addGatePass() {
            this.$refs["gate-pass"].showModal();
        },
        editGatePass(id) {
            this.$refs["gate-pass"].showModal(id);
        },
        gotoUserProfile(studentId){
            this.$router.push({
                name: 'students-summary', 
                params:{
                    type: 'student',
                    id: studentId
                }
            });
        },  
        changeIsUsedStatus(gatePassId){
            this.$confirm({
                message: `Are you sure you want to make this change?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        gatePass
                        .changeIsUsedStatus(gatePassId)
                        .then(response => {
                            this.$snotify.success('Successfully changed')
                            this.getGatePasses();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        approve(gatePassId){
            this.$confirm({
                message: `Are you sure you want to approve?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        gatePass
                        .approve(gatePassId)
                        .then(response => {
                            this.$snotify.success('Successfully approved');
                            this.getGatePasses();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        disapprove(gatePassId){
            this.$confirm({
                message: `Are you sure you want to reject?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        gatePass
                        .disapprove(gatePassId)
                        .then(response => {
                            this.$snotify.success('Successfully approval rejected');
                            this.getGatePasses();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        deleteGatePass(gatePassId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        gatePass
                        .delete(gatePassId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getGatePasses();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        generatePdf(gatePassId){
            gatePass
            .print(gatePassId)
        }
    },
    mounted() {
        this.getGatePasses();
    }
}
</script>

<style scoped>
    
</style>